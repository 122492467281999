import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

//Setting event bus
export const bus = new Vue();

// Importing reset css file
import "@/assets/css/reset.css";
// scroll
Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
