<template>
  <nav class="projet" :class="{ display: firstSlide }">
    <ul class="typesProject" :class="{ typesProjectSlide: !firstSlide }">
      <li
        v-for="item in typeProject"
        :key="item.label"
        class="typesProject__item"
        :class="{ typesProject__itemSlide: !firstSlide }"
      >
        <router-link :to="item.link" class="typesProject__link">
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Projets",
  props: {
    typeProject: Array,
    firstSlide: Boolean,
  },
};
</script>

<style scoped>
.display {
  display: none;
}
.typesProject {
  font-size: 3.5rem;
  margin-top: 6.6rem;
}

.typesProject__item {
  margin-bottom: 35px;
  margin-left: 1rem;
}

.typesProject__link {
  padding: 0 1rem 1rem;
}
@media screen and (min-width: 700px) {
  .display {
    display: initial;
  }
  .typesProject {
    font-size: 10rem;
    margin-top: 0;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    /* line-height: 0.9; */
  }

  .typesProjectSlide {
    align-items: center;
  }

  .typesProject__item {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    margin: 0;
    transform: rotate(180deg);
    padding: 0 1.5rem;
  }

  .typesProject__item--back {
    transform: rotate(180deg) translateY(-35px);
  }
}

@media screen and (min-width: 1200px) {
  .typesProject {
    line-height: 1;
  }
  .typesProject__item {
    margin: 0 10rem;
  }

  .typesProject__itemSlide {
    opacity: 0.2;
  }

  .typesProject__itemSlide:hover {
    opacity: 1;
    transition: ease-in-out 0.5s;
  }
}
</style>
