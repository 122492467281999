<template>
  <nav>
    <ul class="menu" :class="{ menuSlide: !firstSlide }">
      <li>
        <router-link to="#" class="menu__link menu_link--first"
          >Banamisù</router-link
        >
      </li>
      <ul class="menu__right">
        <li>
          <button
            @click.prevent="slideNext"
            v-if="firstSlide"
            class="menu__button"
          >
            Work
          </button>
          <button
            @click.prevent="slidePrev"
            v-else
            class="menu__button"
            :class="{ opacity: isActive }"
          >
            (x)
          </button>
        </li>
        <li>
          <router-link to="/about" class="menu__link menu__link--last"
            >About</router-link
          >
        </li>
      </ul>
    </ul>
  </nav>
</template>

<script>
//Import event bus
import { bus } from "../main";
export default {
  name: "Menu",
  props: {
    firstSlide: Boolean,
  },
  //Communicate to events slide-next and slide-prev with event bus
  methods: {
    slideNext() {
      bus.$emit("slide-next");
    },
    slidePrev() {
      bus.$emit("slide-prev");
    },
  },
};
</script>

<style scoped>
.menu {
  display: flex;
  text-transform: uppercase;
  font-size: 1.4rem;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 1rem 0;
}

.menu__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
}

.menu__link {
  padding: 10px;
}

.menu__button {
  text-transform: uppercase;
  padding: 10px;
  box-sizing: content-box;
  font-size: 1.4rem;
}

/* isActive */

.menuSlide {
  color: rgba(0, 0, 0, 0.2);
}
/* .opacity {
  opacity: 1;
} */

@media screen and (min-width: 700px) {
  .menu {
    font-size: 1.8rem;
    margin: 3rem 4rem 0;
  }
  .menu__button {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1200px) {
  .menu {
    margin: 3rem 9rem 0;
  }
  .menuSlide {
    margin-top: 8rem;
  }
}
</style>
