<template>
  <section class="bottom" :class="{ bottomSlide: firstSlide }">
    <span class="indication" :class="{ opacity: !firstSlide }">{{
      valueIndication
    }}</span>
    <a
      href="#"
      class="bottom__link bottom__link--mail"
      :class="{ display: !firstSlide, 'bottom__link--mailSlide': firstSlide }"
      >bonjour(at)banamisu.fr</a
    >
    <button
      class="bottom__button"
      v-if="firstSlide"
      @click.prevent="slideNext"
      :class="{ bottom__buttonSlide: firstSlide }"
    >
      Up^
    </button>
    <button
      class="bottom__button bottom__button--item"
      :class="{ 'bottom__button--itemSlide': !firstSlide }"
      v-else
      @click.prevent="slidePrev"
    >
      Down ^
    </button>
  </section>
</template>

<script>
//Import event bus
import { bus } from "../main";
export default {
  name: "Footer",
  props: {
    valueIndication: String,
    firstSlide: Boolean,
  },
  //Communicate to events slide-next and slide-prev with event bus
  methods: {
    slideNext() {
      bus.$emit("slide-next");
    },
    slidePrev() {
      bus.$emit("slide-prev");
    },
  },
};
</script>

<style scoped>
.indication {
  position: absolute;
  bottom: 13rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2rem;
}

.bottom {
  position: absolute;
  bottom: 3rem;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  margin: 0 1rem;
}

.bottom__link {
  color: #ff0000;
  text-decoration: underline;
  padding: 1rem;
}

.bottom__button {
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.4rem;
}

.bottom__button--item {
  transform: rotate(-180deg);
}

/* class sur le composant work selon firstSlide */
.opacity {
  opacity: 0.3;
}

.bottom__button--itemSlide {
  position: absolute;
  right: 0;
  bottom: 0;
}

.display {
  display: none;
}

@media screen and (min-width: 700px) {
  .display {
    display: initial;
  }

  .bottom__button--itemSlide {
    position: initial;
  }

  .indication {
    display: none;
  }
  .bottom {
    font-size: 2rem;
    bottom: 5rem;
    margin: 0 4rem;
  }
  .bottomSlide {
    position: initial;
  }
  .bottom__button {
    font-size: 2rem;
  }
  .bottom__link--mailSlide {
    position: absolute;
    top: 5rem;
    right: 4rem;
  }

  .bottom__buttonSlide {
    position: absolute;
    bottom: 5rem;
    right: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .bottomSlide {
    position: absolute;
  }
  .bottom {
    bottom: 8rem;
    margin: 0 9rem;
  }
  .bottom__link--mailSlide,
  .bottom__buttonSlide {
    position: initial;
  }
}
</style>
